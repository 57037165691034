import React from "react";
import NavBar from "../MyComponents/NavBar";
import "../MyComponents/reports.css";
import { useHistory } from "react-router-dom";
import { useEffect } from "react";

export default function KenyaBotDashboard() {

  const initialToken = localStorage.getItem("token");
  const history = useHistory();

  useEffect(() => {
    if (!initialToken) {
      history.push("/login");
    }
  }, [initialToken, history]);
  return (
    <>
      <NavBar detailView={true} />
      <div>
        <iframe
          title="Kenya Bot Dashboard"
          width="600"
          height="373.5"
          src="https://app.powerbi.com/view?r=eyJrIjoiMTk1NzgzZmItMDJlNy00OTIyLWFlOTktOTYyYWNkZjc0ZjcyIiwidCI6ImIyYTViZGZhLTU0MzktNGUxMy04YTBlLWRmODIwMTJiMTQ2YSIsImMiOjZ9"
          frameborder="0"
          allowFullScreen="true"
          id="reports"
        ></iframe>
      </div>
    </>
  );
}
