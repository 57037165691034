// Import the functions you need from the SDKs you need

import firebase from "firebase/compat/app";
import "firebase/auth";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyATAwvC7Mlf5qcVJsxNC6v_8Bo-fDRsqmk",
  authDomain: "mel-project-920f1.firebaseapp.com",
  projectId: "mel-project-920f1",
  storageBucket: "mel-project-920f1.appspot.com",
  messagingSenderId: "124726551956",
  appId: "1:124726551956:web:e3c1041d237f19b7f2db81",
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export default firebase;
