import React from "react";
import NavBar from "../MyComponents/NavBar";
import "../MyComponents/reports.css";

function KDEDashboardPowerBi() {
  return (
    <>
      <NavBar />
      <div>
        <iframe
          title="Kde-Dashboard"
          src="https://app.powerbi.com/view?r=eyJrIjoiZGQ1ZTNmZDctNTYyZi00NTNiLThlNzQtODZiZTJmMzNmNmU1IiwidCI6ImIyYTViZGZhLTU0MzktNGUxMy04YTBlLWRmODIwMTJiMTQ2YSIsImMiOjZ9&pageName=ReportSectiona0abb4168f84e9bd4d78"
          id="reports"></iframe>
      </div>
    </>
  );
}

export default KDEDashboardPowerBi;
