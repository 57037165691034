import React from "react";
import Circle from "./Circle";
import "./eachcard.css";
import { useHistory } from "react-router-dom";

const EachCardNew = ({ show }) => {
  console.log(show);
  const history = useHistory();
  const entering = (e) => {
    console.log(e);
  };
  const exiting = () => {};

  const handleViewDetails = () => {
    if (show.goto.startsWith("http")) {
      // If the link is external
      window.location.href = show.goto;
    } else {
      // If the link is internal
      history.replace(show.goto);
    }
  };
  return (
    <div className="eachBox" onMouseEnter={entering} onMouseLeave={exiting}>
      <div className="eachBoxTop">
        <img
          src={show?.image}
          alt="Circle"
          style={{
            border: "3px solid white",
            borderRadius: "50%",
            height: "100px",
            width: "100px",
            display: "block",
            margin: "auto",
            background: "#1CA069",
          }}
        />
        {/* <div className="each1"></div> */}
        <div className="each2"> {show?.title}</div>
      </div>
      <div className="eachBoxBottom">
        <div className="cardText">{show?.description}</div>
        <div className="buttonview" onClick={handleViewDetails}>
          View details
        </div>
      </div>
    </div>
  );
};

export default EachCardNew;
