import React from "react";
import NavBar from "../MyComponents/NavBar";
import "../MyComponents/reports.css";

function KoboForm() {
  return (
    <>
      <NavBar />
      <div>
        <iframe
          title="KoboForm-Dashboard"
          src="https://app.powerbi.com/view?r=eyJrIjoiMjlmZGI4ZDMtMjkyNi00Y2Q3LTgxYzgtZmY1YWIzODZkZmVlIiwidCI6ImIyYTViZGZhLTU0MzktNGUxMy04YTBlLWRmODIwMTJiMTQ2YSIsImMiOjZ9&pageName=ReportSection"
          id="reports"></iframe>
      </div>
    </>
  );
}

export default KoboForm;
