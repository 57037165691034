import React, {useEffect } from "react";
import NavBar from "../MyComponents/NavBar";
import "../MyComponents/reports.css";
import { useHistory } from "react-router-dom";

function CocoAnalytics() {
  const initialToken = localStorage.getItem("token");
  const history = useHistory();

  useEffect(() => {
    if (!initialToken) {
      history.push("/login");
    }
  }, [initialToken, history]);
  return (
    <>
      <NavBar detailView={true} />
      <div>
        <iframe
          title="coco-Dashboard"
          src="https://app.powerbi.com/view?r=eyJrIjoiN2E1MTVmNmMtNjBlMS00MjZkLWFmYmUtODI3NmM5ZjNiYTMwIiwidCI6ImIyYTViZGZhLTU0MzktNGUxMy04YTBlLWRmODIwMTJiMTQ2YSIsImMiOjZ9"
          id="reports"
        ></iframe>
      </div>
    </>
  );
}

export default CocoAnalytics;
