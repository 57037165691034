import React from "react";
import NavBar from "../MyComponents/NavBar";
import "../MyComponents/reports.css";

function Chatbot() {
  return (
    <>
      <NavBar />
      <div>
        <iframe
          title="Chatbot-dashboard"
          src="https://app.powerbi.com/view?r=eyJrIjoiNDViMDE0YTQtN2I3ZC00Yjg3LWI5ZTAtMDIwMjUyZDI4NWI0IiwidCI6ImIyYTViZGZhLTU0MzktNGUxMy04YTBlLWRmODIwMTJiMTQ2YSIsImMiOjZ9&pageName=ReportSection"
          id="reports"></iframe>
      </div>
    </>
  );
}

export default Chatbot;
