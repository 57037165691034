import React from "react";
import NavBar from "../MyComponents/NavBar";
import "../MyComponents/reports.css";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";

export default function TelegramAdvisoryBot() {
  const initialToken = localStorage.getItem("token");
  const history = useHistory();

  useEffect(() => {
    if (!initialToken) {
      history.push("/login");
    }
  }, [initialToken, history]);
  return (
    <>
      <NavBar detailView={true} />
      <div>
        <iframe
          id="reports"
          title="Advisory bot report"
          width="600"
          height="373.5"
          src="https://app.powerbi.com/view?r=eyJrIjoiM2E3M2Q0ZjYtYjU2NS00YzVjLWJkZTctODcxZWQ3Y2E2YTZmIiwidCI6ImIyYTViZGZhLTU0MzktNGUxMy04YTBlLWRmODIwMTJiMTQ2YSIsImMiOjZ9"
          frameborder="0"
          allowFullScreen="true"
        ></iframe>
      </div>
    </>
  );
}
