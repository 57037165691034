import React, { useState } from "react";
import NavBar from "../MyComponents/NavBar";
import "../MyComponents/login-logout-home.css";

import GenericTile from "react-generic-tile";

import { useHistory } from "react-router-dom";
import {
  IoStatsChartSharp,
  // IoPieChartSharp,
  // IoBarChartSharp,
} from "react-icons/io5";
import EachCard from "../MyComponents/EachCard";
import EachCardNew from "../MyComponents/EachCardNew";
import { Col, Container, Row } from "react-bootstrap";
import frameiamgelight from "../Assets/images/1l.svg";
import frameiamgedark from "../Assets/images/1.svg";
import cocoiamgelight from "../Assets/images/2l.svg";
import cocoiamgedark from "../Assets/images/2.svg";
import kdegoogleiamgelight from "../Assets/images/3l.svg";
import kdegoogleiamgedark from "../Assets/images/3.svg";
import organizationaliamgelight from "../Assets/images/4l.svg";
import organizationaliamgedark from "../Assets/images/4.svg";
import kdepowerbiiamgelight from "../Assets/images/5l.svg";
import kdepowerbiiamgedark from "../Assets/images/5.svg";
import kenyabotImage from "../Assets/images/KENYA BOT PP.svg";
import vistaarbotImage from "../Assets/images/VISTAAR BOT PP.svg";
function Home() {
  const history = useHistory();
  console.log("kenyabotImage", kenyabotImage, vistaarbotImage);
  const [data, setData] = useState([
    {
      title: "Kenya Bot Dashboard",
      image: kenyabotImage,
      description: "Using Kenya Bot data created this PowerBi report",
      goto: "/kenya-Bot-dashboard",
      useCircle: false,
    },
    {
      title: "Farmer Chat India",
      image: vistaarbotImage,
      description: "Using Farmer Chat data created this PowerBi report",
      goto: "/Farmer-Chat-India",
      useCircle: false,
    },
    // {
    //   title: "Frame Operational Dashboard",
    //   image: frameiamgelight,
    //   description: "Using Frame Operational data Created this PowerBi report",
    //   goto: "/frames",
    //   useCircle: true
    // },
    {
      title: "Org-Wide KPI's",
      image: organizationaliamgelight,
      description: "Using Org-Wide KPI's data created this PowerBi report",
      goto: "/Org-Wide",
      useCircle: true,
    },
    {
      title: "Coco Analytics",
      image: cocoiamgelight,
      description: "Using Coco Analytics data created this PowerBi report",
      goto: "/coco",
      useCircle: true,
    },
    // {
    //   title: "KDE Dashboard",
    //   image: kdegoogleiamgelight,
    //   description: "Using KDE data Created this Google studio report",
    //   goto: "/KDEDashboard",
    //   useCircle: true
    // },
    {
      title: "Telegram Advisory Bot",
      image: frameiamgelight,
      description:
        "Using Telegram advisory bot data created this PowerBi report",
      goto: "/TelegramAdvisoryBot",
      useCircle: true,
    },
    {
      title: "Video and Telegram Advisory Dashboard",
      image: cocoiamgelight,
      description:
        "Using Video and telegram advisory bot combined data created this PowerBi report",
      goto: "/coco-telegram-bot-dashobard",
      useCircle: true,
    },
    {
      title: "Ethiopia IVR Dashboard",
      image: organizationaliamgelight,
      description: "Using Ethiopia IVR data created this PowerBi report",
      goto: "/ethiopia-ivr-dashboard",
      useCircle: true,
    },
    {
      title: "Nigeria Bot Dashboard",
      image: cocoiamgelight,
      description: "Using Nigeria Bot data created this PowerBi report",
      goto: "/nigeria-bot-dashboard",
      useCircle: true,
    },
    {
      title: "Farmer-chat AI",
      image: frameiamgelight,
      description: "Using Kenya Bot data created this dashboard",
      goto: "https://dashboard.digitalgreen.org/ai/",
      useCircle: true,
    },
  ]);
  return (
    <>
      <NavBar />
      {/* <div className="home"></div> */}

      <div className="home">
        <h1 className="home-heading ">
          Welcome to Digital Green's MEL Dashboards
        </h1>

        <h3 className="home-subheading ">
          Visualisation reports for various applications
        </h3>
        <Container>
          <Row style={{ margin: "auto" }}>
            {data.map((each) => {
              return (
                <Col style={{ textAlign: "center" }} lg={4} sm={12}>
                  {each.useCircle ? (
                    <EachCard show={each} />
                  ) : (
                    <EachCardNew show={each} />
                  )}
                </Col>
              );
            })}
          </Row>
        </Container>

        <div style={{ display: "none" }} className="flex">
          <div>
            <GenericTile
              header="Frame Operational Dashboard"
              subheader="Using Frame Operational data Created this PowerBi report"
              footer="Click Here to Know More...!"
              icon={<IoStatsChartSharp size={28} />}
              color="Good"
              indicator="Up"
              onClick={() => history.replace("/frames")}
            />
          </div>

          <div>
            <GenericTile
              header="Coco Analytics"
              subheader="Using Coco Analytics data Created this PowerBi report"
              footer="Click Here to Know More...!"
              icon={<IoStatsChartSharp size={28} />}
              color="Good"
              indicator="Up"
              onClick={() => history.replace("/coco")}
            />
          </div>
          <div>
            <GenericTile
              header="KDE Dashboard"
              subheader="Using KDE data Created this Google studio report"
              footer="Click Here to Know More...!"
              icon={<IoStatsChartSharp size={28} />}
              color="Good"
              indicator="Up"
              onClick={() => history.replace("/KDEDashboard")}
            />
          </div>
          <div>
            <GenericTile
              header="Organizational Dashboard"
              subheader="Using Organizational data Created this PowerBi report"
              footer="Click Here to Know More...!"
              icon={<IoStatsChartSharp size={28} />}
              color="Good"
              indicator="Up"
              onClick={() => history.replace("/OrgDashboard")}
            />
          </div>
          <div>
            <GenericTile
              header="Telegram Advisory Bot"
              subheader="Advisory bot data"
              footer="Click Here to Know More...!"
              icon={<IoStatsChartSharp size={28} />}
              color="Good"
              indicator="Up"
              onClick={() => history.replace("/TelegramAdvisoryBot")}
            />
          </div>

          {/* <div>
            <GenericTile
              header="KDE BI Dashboard"
              subheader="Using KDE data Created this PowerBi report"
              footer="Click Here to Know More...!"
              icon={<IoStatsChartSharp size={28} />}
              color="Good"
              indicator="Up"
              onClick={() => history.replace("/KDEDashboardPowerbi")}
            />
          </div> */}
          <EachCard />

          {/* <div>
            <GenericTile
              header="Youtube Analytics"
              subheader="Using Youtube Analytics data Created this PowerBi report"
              footer="Click Here to Know More...!"
              icon={<IoPieChartSharp size={28} />}
              color="Good"
              indicator="Up"
              onClick={() => history.replace("/youtube")}
            />
          </div>
          <div>
            <GenericTile
              header="KoboForm Dashboard"
              subheader="Using Kobo Form data Created this PowerBi report"
              footer="Click Here to Know More...!"
              icon={<IoStatsChartSharp size={28} />}
              color="Good"
              indicator="Up"
              onClick={() => history.replace("/KoboForm")}
            />
          </div>

          <div>
            <GenericTile
              header="Kde Analysis"
              subheader="Using Kishan Diary's Source data Created this PowerBi report"
              footer="Click Here to Know More...!"
              icon={<IoBarChartSharp size={28} />}
              color="Good"
              indicator="Up"
              onClick={() => history.replace("/fpo")}
            />
          </div>
          <div>
            <GenericTile
              header="ChatBot Analysis"
              subheader="Using Various Chatbot's Source data Created this PowerBi report"
              footer="Click Here to Know More...!"
              icon={<IoPieChartSharp size={28} />}
              color="Good"
              indicator="Up"
              onClick={() => history.replace("/chatbot")}
            />
          </div>
          <div>
            <GenericTile
              header="IVR System Analysis"
              subheader="Using IVR System's Source data Created this PowerBi report"
              footer="Click Here to Know More...!"
              icon={<IoBarChartSharp size={28} />}
              color="Good"
              indicator="Up"
              onClick={() => history.replace("/ivr")}
            />
        </div> */}
        </div>
        <hr className="btmLine"></hr>
        <div className="btm">
          <span className="btmlogo">© Digital Green, 2022</span>
          <span className="btmright">
            <span>Privacy Policy</span>
            <span>Terms of Service</span>
          </span>
        </div>
      </div>
    </>
  );
}

export default Home;
