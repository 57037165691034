import React from "react";
import NavBar from "../MyComponents/NavBar";
import "../MyComponents/reports.css";

export default function KDEDashboard() {
  return (
    <>
      <NavBar detailView={true} />
      <div>
        <iframe
          title="Chatbot-dashboard"
          src="https://app.powerbi.com/view?r=eyJrIjoiNGIzYmRiZTctM2Q3Mi00ZGE0LWJkMTItNTczYWI2OTZjOWUzIiwidCI6ImIyYTViZGZhLTU0MzktNGUxMy04YTBlLWRmODIwMTJiMTQ2YSIsImMiOjZ9"
          id="reports"
        ></iframe>
      </div>
    </>
  );
}
