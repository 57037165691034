import React from "react";
// import * as FaIcons from "react-icons/fa";
// import * as AiIcons from "react-icons/ai";
// import { SideBarData } from "./SideBarData";
import "./NavBar.css";
// import { IconContext } from "react-icons";
import user from "../Assets/images/user.svg"
import userlight from "../Assets/images/userlight.svg"
import { useHistory } from "react-router-dom";
import Button from "./button";
import { FaPowerOff } from "react-icons/fa";
import new_dg_logo from "../Pages/new_dg_logo.png"
import { Row } from "react-bootstrap";
import back from "../Assets/images/back.png"
function NavBar(props) {
  const { detailView } = props
  // const [sideBar, setSideBar] = useState(false);

  // const showSideBar = () => setSideBar(!sideBar);
  const changeUser = () => {
    let img = document.querySelector("#userlogo");
    img.src = userlight
  }
  const defaultmake = () => {
    let img = document.querySelector("#userlogo");
    img.src = user
  }
  const history = useHistory();
  return (
    <>
      <div className="navbarMain">
        {/* <Link to="#" className="menu-bars">
            <FaIcons.FaBars onClick={showSideBar} />
          </Link> */}
        <img
          src={new_dg_logo}
          alt="logo"
          className="logo"
          onClick={() => {
            history.replace("/home");
          }}
        />
        {detailView && <button onClick={() => {
          history.replace("/home");
        }} style={{ width: "100px", height: "30px" }} className="logoutbtn">
          Back
        </button>}

        <button
          className="logoutbtn"
          onMouseEnter={changeUser}
          onMouseLeave={defaultmake}
          onClick={() => {
            history.replace("/logout");
          }}>
          <img id="userlogo" src={user} alt="user"
            style={{ height: "24px", width: "24px" }} />
          Sign out
          {/* <FaPowerOff className="poweroff" /> */}
        </button>
      </div>

      {/* <nav className={sideBar ? "nav-menu active" : "nav-menu"}>
          <ul className="nav-menu-items" onClick={showSideBar}>
            <li className="navbar-toggle">
              <Link to="#" className="menu-bars">
                <AiIcons.AiOutlineClose />
              </Link>
            </li>
            {SideBarData.map((item, index) => {
              return (
                <li key={index} className={item.cName}>
                  <Link to={item.path}>
                    <span>{item.title}</span>
                  </Link>
                </li>
              );
            })}
          </ul>
        </nav> */}
    </>
  );
}

export default NavBar;
