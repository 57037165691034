import React, { useContext } from "react";
// import { microsoftprovider, googleprovider } from "../config/authMethods";
// import MicrosoftSignin from "../service/auth";
import { googleprovider } from "../config/authMethods";

import { useHistory } from "react-router-dom";

import firebase from "../config/firebase-config";
import "firebase/compat/auth";
import AuthContext from "../store/auth-context";
// import { useContext } from "react";
// import AuthContext from "../store/auth-context";
import "../MyComponents/login-logout-home.css";

function Login() {
  const history = useHistory();
  const authCtx = useContext(AuthContext);

  const handleOnClick = async (provider) => {
    await provider.setCustomParameters({
      hd: "digitalgreen.org",
    });
    firebase
      .auth()
      .signInWithPopup(provider)

      .then((result) => {
        var token = result.credential.accessToken;
        // eslint-disable-next-line no-unused-vars
        var user = result.user;

        // console.log(token);
        // console.log(user);

        authCtx.login(token);
        console.log("login successful!");
        history.replace("/home");
      })
      .catch((error) => {
        console.log(error.code);
        console.log(error.message);
      });
  };
  return (
    <>
      <div className="login-logout home">
        <h1 className="heading">Welcome to Digital Green MEL Dashboard</h1>

        <p className="paragraph">Please Sign-In To View The Reports!</p>

        <div className="button-center-login ">
          {/* <button
            onClick={() => handleOnClick(microsoftprovider)}
            className="button">
            Using Microsoft-Sign In
          </button> */}
          <button
            onClick={() => handleOnClick(googleprovider)}
            className="button">
            Sign-In with Google
          </button>
        </div>
      </div>
    </>
  );
}
export default Login;
