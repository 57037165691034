import React from "react";
import NavBar from "../MyComponents/NavBar";
import "../MyComponents/reports.css";

export default function Frames() {

  return (
    <>
      <NavBar detailView={true} />
      <div>
        <iframe
          title="Chatbot-dashboard"
          src="https://app.powerbi.com/view?r=eyJrIjoiMWZiMWFiZDgtMDA2Zi00ZGZmLTkyMjQtNzBmZDIyZmFlYTk4IiwidCI6ImIyYTViZGZhLTU0MzktNGUxMy04YTBlLWRmODIwMTJiMTQ2YSIsImMiOjZ9"
          id="reports"></iframe>
      </div>
    </>
  );
}
