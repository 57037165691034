import firebase from "../config/firebase-config";
import "firebase/compat/auth";
import React, { useContext } from "react";
import NavBar from "../MyComponents/NavBar";
import { useHistory } from "react-router-dom";
import AuthContext from "../store/auth-context";
import "../MyComponents/login-logout-home.css";
import { FaPowerOff } from "react-icons/fa";

function Logout() {
  const history = useHistory();
  const authCtx = useContext(AuthContext);

  const handleOnClick = async () => {
    await firebase
      .auth()
      .signOut()
      .then(console.log("log out successfull"))
      .catch((err) => {
        return err;
      });
    authCtx.logout();
    history.replace("/login");
  };

  return (
    <>
      <NavBar />
      <div className="login-logout home">
        <h1 className="heading">
          Thank You for Login into Digital Green MEL Dashboard and See you soon!
        </h1>
        <div className="button-center-logout ">
          <button onClick={() => handleOnClick()} className="button">
            Logout
            <FaPowerOff className="poweroff" />
          </button>
        </div>
      </div>
    </>
  );
}
export default Logout;
