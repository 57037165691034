import React from "react";
import NavBar from "../MyComponents/NavBar";
import "../MyComponents/reports.css";

function Youtube() {
  return (
    <>
      <NavBar />
      <div>
        <iframe
          title="Youtube-Dashboard"
          src="https://app.powerbi.com/view?r=eyJrIjoiMTZkMzNhYzYtNmQzMS00NDBhLTgyNmYtOTVhZTg0MGQyM2NlIiwidCI6ImIyYTViZGZhLTU0MzktNGUxMy04YTBlLWRmODIwMTJiMTQ2YSIsImMiOjZ9&pageName=ReportSection"
          id="reports"></iframe>
      </div>
    </>
  );
}

export default Youtube;
