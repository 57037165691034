import "./App.css";
import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Chatbot from "./Pages/chatbot";
import KDEDashboardPowerBi from "./Pages/kde";
// import Crops from "./Pages/crop";
import Ivrs from "./Pages/ivr";
import Home from "./Pages/home";
import Logout from "./Pages/logout";
import Login from "./Pages/login_page";
import { useContext } from "react";
import AuthContext from "./store/auth-context";
import Youtube from "./Pages/youtube_analytics";
import KoboForm from "./Pages/koboform";
import CocoAnalytics from "./Pages/cocoAnalytics";
import Frames from "./Pages/frames";
import KDEDashboard from "./Pages/KDEDashboard";
import OrgDashboard from "./Pages/OrgDashboard";
import "bootstrap/dist/css/bootstrap.min.css";
import TelegramAdvisoryBot from "./Pages/TelegramAdvisoryBot";
import CocoTelegramBotDashboard from "./Pages/CocoTelegramBotDashboard";
import EthiopiaIVRDashboard from "./Pages/EthiopiaIVRDashboard";
import KenyaBotDashboard from "./Pages/KenyaBotDashboard";
import VistaarDashboard from "./Pages/Vistaar";
import NigeriaBotDashboard from "./Pages/NigeriaBotDashboard";
function App() {
  const authCtx = useContext(AuthContext);
  const isLoggedIn = authCtx.isLoggedIn;
  return (
    <>
      <Switch>
        <Route path="/" exact>
          {isLoggedIn && <Home />}
          {!isLoggedIn && <Login />}
        </Route>

        {isLoggedIn && (
          <Route path="/home">
            <Home />
          </Route>
        )} 

        {/* {isLoggedIn && (
          <Route path="/fpo">
            <Fpos />
          </Route>
        )} */}

        {/* {isLoggedIn && ( */}
          <Route path="/youtube">
            <Youtube />
          </Route>
        {/* )}
        {isLoggedIn && ( */}
          <Route path="/coco">
            <CocoAnalytics />
          </Route>
        {/* )}

        {isLoggedIn && ( */}
          <Route path="/KoboForm">
            <KoboForm />
          </Route>
        {/* )}

        {isLoggedIn && ( */}
          <Route path="/chatbot">
            <Chatbot />
          </Route>
        {/* )}

        {isLoggedIn && ( */}
          <Route path="/ivr">
            <Ivrs />
          </Route>
        {/* )}

        {isLoggedIn && ( */}
          <Route path="/logout">
            <Logout />
          </Route>
        {/* )}

        {isLoggedIn && ( */}
          <Route path="/frames">
            <Frames />
          </Route>
        {/* )}
        {isLoggedIn && ( */}
          <Route path="/KDEDashboard">
            <KDEDashboard />
          </Route>
        {/* )}
        {isLoggedIn && ( */}
          <Route path="/KDEDashboardPowerbi">
            <KDEDashboardPowerBi />
          </Route>
        {/* )}
        {isLoggedIn && ( */}
          <Route path="/Org-Wide">
            <OrgDashboard />
          </Route>
        {/* )}
        {isLoggedIn && ( */}
          <Route path="/TelegramAdvisoryBot">
            <TelegramAdvisoryBot />
          </Route>
        {/* )}
        {isLoggedIn && ( */}
          <Route path="/coco-telegram-bot-dashobard">
            <CocoTelegramBotDashboard />
          </Route>
        {/* )}
        {isLoggedIn && ( */}
          <Route path="/ethiopia-ivr-dashboard">
            <EthiopiaIVRDashboard />
          </Route>
        {/* )}
        {isLoggedIn && ( */}
          <Route path="/kenya-bot-dashboard">
            <KenyaBotDashboard />
          </Route>
        {/* )}
        {isLoggedIn && ( */}
          <Route path="/Farmer-Chat-India">
            <VistaarDashboard />
          </Route>
        {/* )}
        {isLoggedIn && ( */}
          <Route path="/nigeria-bot-dashboard">
            <NigeriaBotDashboard />
          </Route>
        {/* )} */}

        <Route path="/login">
          <Login />
        </Route>

        <Route path="*">
          {isLoggedIn && <Redirect to="/home" />}
          {!isLoggedIn && <Redirect to="/login" />}
        </Route>
      </Switch>
    </>
  );
}

export default App;
